export {
	IconUser,
	IconHome,
	IconChevronLeft,
	IconChevronRight,
	IconChevronDown,
	IconEdit,
	IconSearch,
	IconCalendarEvent,
	IconListDetails,
	IconIroning3,
	IconTrash,
	IconCheck,
	IconPlus,
	IconDeviceFloppy,
	IconBuildingCommunity,
	IconGhostFilled,
	IconPointFilled,
	IconPhoto,
	IconDatabaseOff,
	IconAdjustmentsHorizontal,
	IconLogout,
	IconChartAreaFilled,
	IconCoins,
	IconLock,
	IconFileSpreadsheet,
	IconCurrencyRubel,
	IconArrowRight,
	IconFolder,
	IconPencil,
	IconLogout2,
	IconRefresh,
	IconWand,
	IconBrush,
	IconFileTypeXls,
	IconInfoCircle,
	IconArchive,
	IconBriefcase2,
	IconX,
	IconDownload,
	IconChevronUp,
	IconThumbUp,
	IconThumbDown,
	IconCircleCheck,
	IconReportAnalytics,
	IconRepeat,
	IconSquareNumber1,
	IconUrgent,
	IconUsers,
	IconQrcode,
	IconMinus,
	IconSwitchVertical,
	IconHelp,
	IconFolderPlus,
	IconClipboard,
	IconCubePlus,
	IconLayersSubtract,
	IconKey,
	IconSend,
	IconArrowLeft,
	IconArrowBackUp,
	IconArrowUp,
	IconArrowsVertical,
	IconPrinter,
	IconRobot,
	IconSun,
	IconMoon,
	IconChartBar,
	IconCaretRight,
	IconCamera,
	IconCalendar,
	IconWifiOff,
	IconDivide,
	IconLink,
	IconUnlink,
	IconCalculator,
	IconHammer,
	IconLayoutGrid,
	IconSend2,
	IconPlaystationCircle,
	IconGraph,
	IconTable,
	IconUpload,
	IconCopy,
	IconNews,
	IconAppWindow,
	IconLogin2,
	IconScript,
	IconDotsVertical,
	IconKeyboard,
	IconRestore,
	IconForms,
	IconTextPlus,
	IconArrowNarrowUp,
	IconArrowNarrowDown,
	IconArchiveOff,
	IconLockOpen,
	IconLinkPlus,
	IconCopyPlus,
	IconFilter,
	IconSpeakerphone,
	IconFolderRoot,
	IconSourceCode,
	IconFlask,
	IconRocket,
	IconBan,
	IconExternalLink,
	IconRefreshDot,
	IconSelect,
} from '@tabler/icons-react';
export type { IconProps } from '@tabler/icons-react';
